import {HTTP} from '../../../http-common'
import helper from '../index'

export default {
  getCommission: async() => {
    const res = await HTTP.get('/stripe-commission-get', {
      headers: helper.setHeaders()
    })
    return res.data.commission_percent
  },
  updateCommission: async(data) => {
    const res = await HTTP.post('/stripe-commission-update', data, {
      headers: helper.setHeaders()
    })
    return res.data
  }
}
