<template>
  <div v-frag>
    <v-container fluid>
      <v-card class='rounded-lg' elevation='3'>
        <v-container fluid>
          <v-row>
            <v-col cols='12' lg='8'>
              <v-simple-table class='headerTable'>
                <template #default>
                  <thead>
                  <tr>
                    <th class='text-center'>Total Commissions Collected</th>
                    <th class='text-center border-left'>Total Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class='text-center'>
                      {{ formatMoney(commissionAndAmount.totalCommission) }}
                    </td>
                    <td class='text-center border-left'>
                      {{ formatMoney(commissionAndAmount.totalAmount) }}
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols='12' lg='4' class='button-col'>
              <v-btn
                block
                class='white--text text-capitalize buttonHeader py-6 py-lg-7'
                color='blue'
                @click='dialogCommission = true'
              >
                <v-icon class='pr-4'>mdi-percent</v-icon>
                MCBA Commission
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>

    <v-container fluid>
      <v-alert
        v-model='alertOptions.show'
        :color='alertOptions.color'
        class='rounded-xl'
        dark
        dense
        dismissible
        icon='mdi-check-circle-outline'
        prominent
        transition='scale-transition'
        width='100%'
      >
        <h3 class='mt-2'>{{ alertOptions.title }}</h3>
        <p>{{ alertOptions.body }}</p>
      </v-alert>

      <v-row align='center' align-content='center' justify='center'>
        <v-col cols='12'>
          <v-card class='rounded-lg' elevation='3'>
            <v-card-title>
              <v-row>
                <v-col cols='12' lg='5'>
                  {{ sections.charities || 'Charities title' }}
                </v-col>

                <v-col cols='12' lg='5'>
                  <v-text-field
                    v-model='search'
                    :loading='loading'
                    :readonly='loading'
                    class='rounded-lg'
                    dense
                    filled
                    hide-details
                    placeholder='Search'
                    prepend-inner-icon='mdi-magnify'
                    rounded
                  ></v-text-field>
                </v-col>
                <v-col cols='12' lg='2'>
                  <v-btn
                    block
                    class='white--text text-capitalize mr-0'
                    color='blue'
                    height='100%'
                    outlined
                    @click='newCharity'
                  >
                    <v-icon>mdi-plus</v-icon>
                    Add New
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>

            <v-container fluid>
              <v-data-table
                v-if='showSuspended'
                :headers='headers'
                :items='suspendedCharities'
                :options.sync='charitiesTableMeta'
                :server-items-length='charitiesNumber'
                :items-per-page='10'
                :loading='loading'
                class='elevation-1'
                item-key='id'
                @update:options='getAllCharities'
              >
                <template #item.name={item}>
                  <span class='charityName' @click='clickRow(item)'>{{ item.name }}</span>
                </template>
                <template #item.donations={item}>
                  <span> {{ formatMoney(item.donations) }} </span>
                </template>
                <template #item.mcbaCommission={item}>
                  <span> {{ formatMoney((10 * item.donations) / 100) }} </span>
                </template>
                <template #item.actions={item}>
                  <v-btn
                    :loading='loadingSuspendCharity && item.id === indexForUndo'
                    depressed
                    class='mr-2 my-1 pa-0 green white--text rounded-sm'
                    height='32'
                    min-width='32'
                    @click='toggleStatusCharity(item, false)'
                  >
                    <v-icon>mdi-undo</v-icon>
                  </v-btn>
                  <v-btn
                    depressed
                    class='mr-2 my-1 pa-0 red white--text rounded-sm'
                    height='32'
                    min-width='32'
                    @click='charityForDelete(item)'
                  >
                    <v-icon>mdi-close-thick</v-icon>
                  </v-btn>
                </template>
                <template #footer.prepend>
                  <v-btn
                    v-if='showSuspended'
                    class='text-capitalize font-weight-bold'
                    color='primary'
                    outlined
                    @click='changeTable'
                  >
                    return to active charities
                  </v-btn>
                </template>
              </v-data-table>

              <v-data-table
                v-if='!showSuspended'
                :headers='headers'
                :items='charities'
                :options.sync='charitiesTableMeta'
                :server-items-length='charitiesNumber'
                :items-per-page='10'
                :loading='loading'
                loading-text='Loading charities... Please wait'
                class='elevation-1'
                item-key='id'
                @update:options='getAllCharities'
              >
                <template #item.name={item}>
                  <span class='charityName' @click='clickRow(item)'>{{ item.name }}</span>
                </template>
                <template #item.donations={item}>
                  <span> {{ formatMoney(item.donations) }} </span>
                </template>
                <template #item.mcba_commission={item}>
                  <span> {{ formatMoney(item.mcba_commission) }} </span>
                </template>
                <template #item.actions={item}>
                  <v-icon
                    class='mr-2 my-1 pa-2 blue white--text rounded-sm'
                    small
                    @click='editCharity(item)'
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    class='mr-2 my-1 pa-2 orange white--text rounded-sm'
                    small
                    @click='toggleStatusCharity(item, true)'
                  >
                    mdi-timer-off
                  </v-icon>
                </template>
                <template #footer.prepend>
                  <v-btn
                    v-if='!showSuspended'
                    class='text-capitalize font-weight-bold'
                    color='warning'
                    outlined
                    @click='changeTable'
                  >
                    view all suspended charities
                  </v-btn>
                </template>
              </v-data-table>
            </v-container>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model='dialog' max-width='1000px' persistent scrollable>
        <v-card>
          <v-card-title>
            <span class='headline'>{{ dialogTitle }}</span>
          </v-card-title>

          <v-card-text class='py-2 px-4'>
            <v-container>
              <v-form
                ref='charityForm'
                class='modalForm'
                lazy-validation
              >
                <v-row>
                  <v-col>
                    <p class='font-weight-bold label'>Charity Name*</p>
                    <v-text-field
                      ref='charityNameInput'
                      v-model='editedItem.name'
                      :rules='[fieldRules.required]'
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p class='font-weight-bold label'>Address*</p>
                    <v-text-field
                      v-model='editedItem.address'
                      :rules='[fieldRules.required]'
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row md='2' sm='3'>
                  <v-col>
                    <p class='font-weight-bold label'>
                      Main Point of Contact Name
                    </p>
                    <v-text-field
                      v-model='editedItem.contact_name'
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <p class='font-weight-bold label'>
                      Main Point of Contact Email
                    </p>
                    <v-text-field
                      v-model='editedItem.contact_email'
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <p class='font-weight-bold label'>
                      Main Point of Contact Phone
                    </p>
                    <v-text-field
                      v-model='editedItem.contact_phone'
                      :rules='[fieldRules.phone]'
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row md='4' sm='6'>
                  <v-col>
                    <p class='font-weight-bold label'>General Phone</p>
                    <v-text-field
                      v-model='editedItem.general_phone'
                      :rules='[fieldRules.phone]'
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <p class='font-weight-bold label'>General Email</p>
                    <v-text-field
                      v-model='editedItem.general_email'
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions class='d-flex direction-row justify-center'>
            <v-btn
              class='px-14 ma-4 font-weight-bold'
              color='blue'
              large
              outlined
              @click='closeDialog'
            >
              Cancel
            </v-btn>
            <v-btn
              :loading='loadingCreateCharity'
              class='px-14 ma-4 font-weight-bold white--text'
              color='primary'
              large
              @click='saveCharity'
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model='dialogSuspend' max-width='500px'>
        <v-card class='d-flex flex-column align-start pa-2'>
          <v-card-title class='text-center mb-5'>
            <v-icon class='mr-4 white--text' color='warning' large left>mdi-alert</v-icon>
            Suspend Charity
          </v-card-title>
          <v-card-subtitle>
            <b>You are about to suspend the following charity:</b>
          </v-card-subtitle>
          <p class='ml-8 py-4 font-weight-bold secondary--text'>
            {{ editedItem.name }}
          </p>
          <v-card-actions class='align-self-center'>
            <v-spacer></v-spacer>
            <v-btn
              class='px-14 mx-4 font-weight-bold text-capitalize'
              color='primary'
              large
              outlined
              @click='closeSuspend'
            >
              Cancel
            </v-btn>
            <v-btn
              :loading='loadingSuspendCharity'
              class='px-14 mx-4 font-weight-bold text-capitalize white--text'
              color='warning'
              large
              @click='suspendCharityConfirm'
            >
              Suspend
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model='dialogDelete' max-width='500px'>
        <v-card class='d-flex flex-column align-start pa-2'>
          <v-card-title class='text-center mb-5'>
            <v-icon class='mr-4 white--text' color='error' large left>mdi-alert</v-icon>
            Delete Charity
          </v-card-title>
          <v-card-subtitle>
            <b>You are about to delete the following charity:</b>
          </v-card-subtitle>
          <p class='ml-8 py-4 font-weight-bold secondary--text'>
            {{ deletedItem.name }}
          </p>
          <v-card-actions class='align-self-center'>
            <v-spacer></v-spacer>
            <v-btn
              class='px-14 mx-4 font-weight-bold white--text'
              color='primary'
              large
              outlined
              @click='dialogDelete = false'
            >
              Cancel
            </v-btn>
            <v-btn
              :loading='loadingDeleteCharity'
              class='px-14 mx-4 font-weight-bold white--text'
              color='primary'
              large
              @click='deleteCharityConfirm'
            >
              Confirm
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model='dialogCommission'
        max-width='500px'
        persistent
        scrollable
        transition='dialog-bottom-transition'
      >
        <v-card>
          <v-card-title>
            <span>MCBA Commission</span>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-subtitle>
            The <strong>MCBA</strong> Commission will be considered in future
            donations made by the member's employees, including transfers and
            subscriptions.<br/>
            <strong>Note:</strong>
            Existing subscriptions will continue with the previous percentage.
          </v-card-subtitle>
          <v-card-text>
            <v-container>
              <v-form lazy-validation>
                <p class='font-weight-bold'>MCBA Commission</p>
                <v-currency-field
                  v-model='commission'
                  :max='100'
                  dense
                  maxlength='6'
                  outlined
                  prefix='%'
                  required
                />
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions class='d-flex direction-row justify-center'>
            <v-btn
              :disabled='loading'
              class='px-14 mx-4 font-weight-bold'
              color='primary'
              large
              outlined
              @click='dialogCommission = false'
            >
              Cancel
            </v-btn>
            <v-btn
              :disabled='loading'
              :loading='loading'
              class='px-14 mx-4 font-weight-bold white--text'
              color='primary'
              large
              @click='updateCommission'
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import commissionService from '../../services/mcbaCommission'

export default {
  data() {
    return {
      charitiesTableMeta: {},
      charitiesNumber: null,
      loadingCreateCharity: false,
      loadingSuspendCharity: false,
      loadingDeleteCharity: false,
      indexForUndo: -1,
      loading: false,
      searchTimer: null,
      search: '',
      showSuspended: false,
      alertOptions: {
        show: false
      },
      headers: [
        { text: 'Charity', align: 'start', value: 'name', divider: true },
        { text: 'Address', value: 'address', divider: true },
        { text: 'Donation', value: 'donations', divider: true },
        { text: 'Vol. Hours Received', value: 'hours_received', divider: true },
        { text: 'Vol. Hours Open', value: 'hours_open', divider: true },
        { text: 'MCBA Commission', value: 'mcba_commission', sortable: false, divider: true },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      editedItem: {},
      editedIndex: -1,
      deletedItem: {},
      editedStatus: false,
      deletedIndex: -1,
      dialogCommission: false,
      dialog: false,
      dialogTitle: '',
      dialogDelete: false,
      dialogSuspend: false,
      fieldRules: {
        required: v => !!v || 'Field is required',
        phone: v => v ? ((/^[0-9]+$/.test(v) && v?.length === 10) || 'Value must be a number and be equal to 10 characters') : true
      }
    }
  },
  computed: {
    ...mapState({
      charities: s => s.charityStore.charities,
      suspendedCharities: s => s.charityStore.suspendedCharities,
      sections: s => s.cmsStore.sections
    }),
    ...mapGetters({
      commission: 'commission'
    }),
    commissionAndAmount() {
      let totalCommission = 0
      let totalAmount = 0
      this.charities.forEach(charity => {
        totalCommission += charity.mcba_commission
        totalAmount += charity.donations
      })
      return { totalCommission, totalAmount }
    }
  },
  watch: {
    search() {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(async () => {
        this.charitiesTableMeta.page === 1 ? await this.getAllCharities() : this.charitiesTableMeta.page = 1
      }, 750)
    }
  },
  methods: {
    ...mapActions('charityStore', [
      'getSuspendedCharities',
      'getCharities',
      'toggleSuspendCharity',
      'getSingleCharity',
      'createCharity',
      'updateCharity',
      'deleteCharity'
    ]),
    async getAllCharities() {
      this.loading = true
      const { page, sortBy, sortDesc, itemsPerPage } = this.charitiesTableMeta
      const params = {
        page,
        sort: sortBy[0],
        method: sortBy[0] ? (sortDesc[0] ? 'DESC' : 'ASC') : undefined,
        itemsPerPage: itemsPerPage === -1 ? this.charitiesNumber : itemsPerPage,
        search: this.search || undefined
      }
      const { meta: { total } } = this.showSuspended ? await this.getSuspendedCharities(params) : await this.getCharities(params)
      this.charitiesNumber = total
      this.loading = false
    },
    closeDialog() {
      this.dialog = !this.dialog
      this.$refs.charityForm.reset()
    },
    newCharity() {
      this.dialogTitle = 'New Charity'
      this.dialog = true
    },
    async editCharity(item) {
      this.editedIndex = this.charities.indexOf(item)
      this.editedItem = await this.getSingleCharity(item.id)
      this.dialogTitle = 'Edit Charity'
      this.dialog = !this.dialog
    },
    charityForDelete(item) {
      this.deletedIndex = this.suspendedCharities.indexOf(item)
      this.deletedItem = Object.assign({}, item)
      this.dialogDelete = !this.dialogDelete
    },
    async saveCharity() {
      if (this.$refs.charityForm.validate()) {
        this.loadingCreateCharity = true
        this.editedIndex > -1
          ? await this.updateCharity(this.editedItem)
          : await this.createCharity(this.editedItem)
        this.loadingCreateCharity = false
        this.closeDialog()
        await this.getAllCharities()
      }
    },
    async deleteCharityConfirm() {
      this.loadingDeleteCharity = true
      await this.deleteCharity(this.deletedItem.id)
      this.loadingDeleteCharity = false
      this.dialogDelete = !this.dialogDelete
      await this.getAllCharities()
    },
    async suspendCharityConfirm() {
      this.loadingSuspendCharity = true
      await this.toggleSuspendCharity({ id: this.editedItem.id, suspended: this.editedStatus })
      await this.getAllCharities()
      this.loadingSuspendCharity = false
      this.dialogSuspend = false
    },
    async updateCommission() {
      const res = await commissionService.updateCommission({ commission_percent: this.commission })
      this.dialogCommission = false
      if (res.success) {
        this.openAlert('commission')
        await this.$store.dispatch('commission', this.commission)
      } else {
        this.openAlert('error')
      }
    },
    toggleStatusCharity(item, status) {
      this.editedItem = item
      this.editedStatus = status
      if (status) {
        this.dialogSuspend = true
      } else {
        this.indexForUndo = item.id
        this.suspendCharityConfirm()
      }
    },
    closeSuspend() {
      this.dialogSuspend = false
    },
    async changeTable() {
      this.showSuspended = !this.showSuspended
      this.charitiesTableMeta.page = 1
      this.charitiesTableMeta.itemsPerPage = 10
      await this.getAllCharities()
    },
    clickRow(value) {
      this.$router.push(`charity-events/${ value.id }`)
    },
    openAlert(operation) {
      const options = {
        show: true,
        color: 'success',
        title: '',
        body: ''
      }
      switch (operation) {
        case 'created':
          options.title = 'Charity created!'
          options.body = 'Charity was successfully created.'
          break
        case 'updated':
          options.title = 'Charity updated!'
          options.body = 'Charity was successfully updated.'
          break
        case 'deleted':
          options.title = 'Charity deleted!'
          options.body = 'Charity was successfully deleted.'
          break
        case 'commission':
          options.title = 'MCBA Commission updated!'
          options.body = 'The commission was successfully updated.'
          break
        case 'error':
          options.color = 'error'
          options.title = 'Something went wrong!'
          options.body = 'Something has gone wrong, please try again.'
          break
        default:
          break
      }
      this.alertOptions = options
    },
    formatMoney(value) {
      if (typeof value == 'number') {
        return value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        })
      }
    }
  }
}
</script>

<style>
.charityName {
  text-decoration: underline;
}
.charityName:hover {
  color: #000;
  cursor: pointer;
}
.modalForm .label {
  margin-bottom: 2px;
}
.modalForm .v-input__slot {
  min-height: 42px !important;
}
.modalForm .col {
  padding-top: 0;
  padding-bottom: 0;
}
.border-left {
  border-left: 1px solid #eee;
}
.button-col {
  padding-right: 3rem;
  padding-left: 3rem;
}
.buttonHeader {
  display: flex;
  flex-direction: column;
  margin-top: 20px !important;
  padding: 0 !important;
}
</style>
